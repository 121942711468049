<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="物资详情"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <!-- 排序 -->
    </div>
    <div class="content contentbox">
      <!-- 点击排序 -->

      <!-- 列表 -->
      <van-empty description="空" v-if="projectlist.length === 0" />

      <div class="supplies" v-for="(item, index) in projectlist" :key="index">
        <div class="supplies-title">{{ item.strprodname }}</div>
        <div class="supplies-text">采购申请编号: {{ item.ordercode }}</div>
        <div class="supplies-text">物资编码: {{ item.strprodcode }}</div>
        <div class="supplies-text">
          数量: {{ item.number }}{{ item.measunit }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      projectlist: [],
    };
  },
  created() {
    this.toPackOrderInfo();
  },
  computed: {
    packId() {
      return this.$route.query.packId;
    },
    activeNamesdion() {
      return this.$route.query.activeNamesdion;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    onClickLeft() {
      // window.history.go(-1);
      let query = {
        activeNamesdion: this.activeNamesdion,
        typeof: 1,
        id: this.$route.query.stockaffirmId,
        appStatus: this.appStatus,
      };
      this.$router.push({ name: "tenderentrustDetails", query });
    },
    async toPackOrderInfo() {
      const params = {
        packId: this.packId,
      };
      let data = await this.$api.ec.toPackOrderInfo(params);
      this.projectlist = data.data;
    },
  },
};
</script>
